.Desc_Container {
  margin-top: 3%;
  height: 23rem;
}

.DescImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
