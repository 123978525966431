.test {
  margin: 1%;
  margin-top: 2%;
}

.Card {
  border: none;
  margin: 2% auto;
}

Card {
  min-height: 200px;
}

@media screen and (max-width: 695px) {
  .Card {
    margin: 2% auto;
  }
}

@media screen and (max-width: 1344px) {
  .test {
    margin: 0%;
  }
}

@media screen and (max-width: 1344px) {
  .RowLastCard {
  }

  .Card {
    margin: auto;
    margin-top: 2%;
  }
}

.carousel-indicators {
  opacity: 0;
}

@media screen and (max-width: 577px) {
  .Card {
    max-width: 70%;
  }
}
