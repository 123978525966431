@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");


.InstaTest {
  max-width: 100%;
  position: relative;
  margin: 5%;
}

.InstagramCompHeader {
  margin-bottom: 5%;
  font-family: "Lora", serif;
}

.InstaCompCont {
  text-align: center;
  max-width: 70%;
  margin-bottom: 15%;
  margin-top: 3%;
}
