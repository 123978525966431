@import url("https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:ital,wght@0,400;1,200&display=swap");

.AboutPageHeader {
  font-family: "Lora", serif;
}
.AboutContianer {
  background-color: #f7ebed;
  text-align: center;
  padding: 5%;
  margin-bottom: 7%;
  margin-top: 3%;
}

.AboutDesc {
  width: 60%;
  margin: 3% auto;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  
}

.AboutInfo {
  padding-top: 5%;
  margin: 5%;
}

.AboutDetailedDesc {
  width: 80%;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 2%;
  background-color: white;
  height: 100%;
}

.AboutActualDesc {
  width: 80%;
  margin: auto;
  text-align: left;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}

.AboutImg {
  width: 100%;
  align-items: center;
  height: 100%;
  object-fit: cover;
}

.AboutTitle {
  margin: 5%;
}

.AboutWho {
  margin: 5%;
}

@media screen and (max-width: 991px) {
  .AboutDetailedDesc {
    margin: 10%;
  }
}

@media screen and (max-width: 1162px) {
  .AboutActualDesc {
    width: 90%;
    margin: auto;
    text-align: left;
    align-items: center;
  }
}

@media screen and (max-width: 1080px) {
  .AboutActualDesc {
    width: 90%;
    margin: auto;
    text-align: left;
    align-items: center;
  }

  .AboutDetailedDesc {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 990px) {
  .AboutDetailedDesc {
    margin-top: 5%;
  }
}
