.footer {
  width: 100%;
  background-color: #292325;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  height: 3rem;
  text-align: center;
}

.footerDesc {
  margin-top: 10px;
}

.FooterText {
  color: white;
  font-weight: lighter;
  margin-bottom: 0;
  padding-top: 10px;
  background-color: #292325;
}
