@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

.DescText {
  background-color: #f7ebed;
  margin-top: 2%;
  text-align: center;
  padding: 0.5%;
  font-family: "Lora", serif;
}

.DescText_info {
  text-align: center;
  margin: 2% 5%;
  font-weight: lighter;
  /* padding-bottom: 5%; */
  padding-top: 5%;
  margin-bottom: 0%;
}

.Main_DescText {
  margin-top: 5%;
}
