.brand_logo {
  float: left;

  margin-right: 25px;

  margin-top: 10px;
}

.brand_name {
  margin-top: 4%;
  font-weight: bolder;
  margin-right: 5%;
}

.test {
  background-color: white;
}

.navbar {
  text-align: center;
}

@media screen and (max-width: 377px) {
  /* .navbar-toggler {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%; 

  } */
}

@media screen  and (max-width:473px) {
  .navbar-toggler{
    margin:auto;
    
  }

  .navbar-brand{
align-items: center;
  }
  .brand_logo{
    width: 100%
  }
  }

.NavbarLinks {
  text-decoration: none;
}
