@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

.CollectionTitle {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 2%;
  font-family: "Lora", serif;
}

.carouselitem {
  display: inline-block;
}
