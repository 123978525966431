@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:ital,wght@0,400;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Montserrat&display=swap);

.brand_logo {
  float: left;

  margin-right: 25px;

  margin-top: 10px;
}

.brand_name {
  margin-top: 4%;
  font-weight: bolder;
  margin-right: 5%;
}

.test {
  background-color: white;
}

.navbar {
  text-align: center;
}

@media screen and (max-width: 377px) {
  /* .navbar-toggler {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%; 

  } */
}

@media screen  and (max-width:473px) {
  .navbar-toggler{
    margin:auto;
    
  }

  .navbar-brand{
align-items: center;
  }
  .brand_logo{
    width: 100%
  }
  }

.NavbarLinks {
  text-decoration: none;
}

.main_picture {
  margin-top: 2%;
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

.main_pic_container {
  text-align: center;
  
}

.DescriptionTextCont {
  padding-left: 0;
  margin-left: 0;
}

.DescriptionImgCont {
}

.DescText {
  background-color: #f7ebed;
  margin-top: 2%;
  text-align: center;
  padding: 0.5%;
  font-family: "Lora", serif;
}

.DescText_info {
  text-align: center;
  margin: 2% 5%;
  font-weight: lighter;
  /* padding-bottom: 5%; */
  padding-top: 5%;
  margin-bottom: 0%;
}

.Main_DescText {
  margin-top: 5%;
}

.Desc_Container {
  margin-top: 3%;
  height: 23rem;
}

.DescImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PromImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
  padding: 2%;
}

.box {
  display: flex;
  justify-content: space-between;
}

.PromHeader{
  font-family: "Lora", serif;
}

.CollectionTitle {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 2%;
  font-family: "Lora", serif;
}

.carouselitem {
  display: inline-block;
}


.test{
    margin: 1%;
    margin-top: 2%;
 }
 
 .Card{
     border:none;
     margin: 2% auto;
 }
 
 Card{
     min-height: 200px;
 }
 
 @media screen and (max-width:695px) {
     .Card{
         margin: 2% auto;
     }
 }
 
 @media screen and (max-width:1344px){
     .test{
         margin: 0%;
     }
 }
 
 @media screen and (max-width:1344px) {
     .RowLastCard{
     }
 
     .Card{
         margin: auto;
         margin-top: 2%;
     }
     
 }
 
 .carousel-indicators{
     opacity: 0;
 }
 
 @media screen and (max-width:577px){
     .Card{
      max-width: 70%;
     }
 }
.test {
  margin: 1%;
  margin-top: 2%;
}

.Card {
  border: none;
  margin: 2% auto;
}

Card {
  min-height: 200px;
}

@media screen and (max-width: 695px) {
  .Card {
    margin: 2% auto;
  }
}

@media screen and (max-width: 1344px) {
  .test {
    margin: 0%;
  }
}

@media screen and (max-width: 1344px) {
  .RowLastCard {
  }

  .Card {
    margin: auto;
    margin-top: 2%;
  }
}

.carousel-indicators {
  opacity: 0;
}

@media screen and (max-width: 577px) {
  .Card {
    max-width: 70%;
  }
}

.InstaTest {
  max-width: 100%;
  position: relative;
  margin: 5%;
}

.InstagramCompHeader {
  margin-bottom: 5%;
  font-family: "Lora", serif;
}

.InstaCompCont {
  text-align: center;
  max-width: 70%;
  margin-bottom: 15%;
  margin-top: 3%;
}

.AboutPageHeader {
  font-family: "Lora", serif;
}
.AboutContianer {
  background-color: #f7ebed;
  text-align: center;
  padding: 5%;
  margin-bottom: 7%;
  margin-top: 3%;
}

.AboutDesc {
  width: 60%;
  margin: 3% auto;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  
}

.AboutInfo {
  padding-top: 5%;
  margin: 5%;
}

.AboutDetailedDesc {
  width: 80%;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 2%;
  background-color: white;
  height: 100%;
}

.AboutActualDesc {
  width: 80%;
  margin: auto;
  text-align: left;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}

.AboutImg {
  width: 100%;
  align-items: center;
  height: 100%;
  object-fit: cover;
}

.AboutTitle {
  margin: 5%;
}

.AboutWho {
  margin: 5%;
}

@media screen and (max-width: 991px) {
  .AboutDetailedDesc {
    margin: 10%;
  }
}

@media screen and (max-width: 1162px) {
  .AboutActualDesc {
    width: 90%;
    margin: auto;
    text-align: left;
    align-items: center;
  }
}

@media screen and (max-width: 1080px) {
  .AboutActualDesc {
    width: 90%;
    margin: auto;
    text-align: left;
    align-items: center;
  }

  .AboutDetailedDesc {
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 990px) {
  .AboutDetailedDesc {
    margin-top: 5%;
  }
}

.footer {
  width: 100%;
  background-color: #292325;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  height: 3rem;
  text-align: center;
}

.footerDesc {
  margin-top: 10px;
}

.FooterText {
  color: white;
  font-weight: lighter;
  margin-bottom: 0;
  padding-top: 10px;
  background-color: #292325;
}

.ContactMailLink{
  font-size: smaller;
}

.ContactActualCont {
  background-color: #f7ebed;

  margin-top: 3%;
  margin-bottom: 5%;
  padding: 5%;
}

.ContactDesc {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  padding: 3%;
  padding-top: 1%;
  font-family: "Montserrat", sans-serif;
}

.ContactHeading {
  text-align: center;
  padding-top: 5%;
  margin-top: 0%;
  /* margin-bottom: 2%; */
  font-family: "Lora", serif;
}

.ContactInfo {
  margin: 4% auto;
  text-align: center;
  margin: 2%;
  padding: 2%;
  margin-top: 0%;
  padding-top: 0%;
  padding-bottom: 1%;
  font-family: "Montserrat", sans-serif;
}

.ContactAddr {
  text-align: center;
  margin: 3%;
  padding: 5%;
  padding-top: 0;
  font-family: "Montserrat", sans-serif;
}

.ContactForm {
  background-color: rgb(255, 255, 255);

  width: 70%;
  margin: 3% auto;
  font-family: "Montserrat", sans-serif;
}

.ContactFormInner {
  display: inline-block;

  text-align: center;
}

.ContactFormInnerBox {
  background-color: white;
  margin: 10%;
  margin-bottom: 5%;
}

.FormEle {
  border: none;
  background: transparent;
  border-bottom: 1px solid #000000;
  margin: 5% 0%;
}

.dummy {
  background-color: #f7ebed;
  padding-bottom: 10%;
}

.WhatsappButton{
  padding: 1%;
}
body {
  width: 100%;
}

