@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");


.PromImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
  padding: 2%;
}

.box {
  display: flex;
  justify-content: space-between;
}

.PromHeader{
  font-family: "Lora", serif;
}
