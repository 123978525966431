.main_picture {
  margin-top: 2%;
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

.main_pic_container {
  text-align: center;
  
}
