@import url("https://fonts.googleapis.com/css2?family=Lora&family=Montserrat&display=swap");

.ContactMailLink{
  font-size: smaller;
}

.ContactActualCont {
  background-color: #f7ebed;

  margin-top: 3%;
  margin-bottom: 5%;
  padding: 5%;
}

.ContactDesc {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  padding: 3%;
  padding-top: 1%;
  font-family: "Montserrat", sans-serif;
}

.ContactHeading {
  text-align: center;
  padding-top: 5%;
  margin-top: 0%;
  /* margin-bottom: 2%; */
  font-family: "Lora", serif;
}

.ContactInfo {
  margin: 4% auto;
  text-align: center;
  margin: 2%;
  padding: 2%;
  margin-top: 0%;
  padding-top: 0%;
  padding-bottom: 1%;
  font-family: "Montserrat", sans-serif;
}

.ContactAddr {
  text-align: center;
  margin: 3%;
  padding: 5%;
  padding-top: 0;
  font-family: "Montserrat", sans-serif;
}

.ContactForm {
  background-color: rgb(255, 255, 255);

  width: 70%;
  margin: 3% auto;
  font-family: "Montserrat", sans-serif;
}

.ContactFormInner {
  display: inline-block;

  text-align: center;
}

.ContactFormInnerBox {
  background-color: white;
  margin: 10%;
  margin-bottom: 5%;
}

.FormEle {
  border: none;
  background: transparent;
  border-bottom: 1px solid #000000;
  margin: 5% 0%;
}

.dummy {
  background-color: #f7ebed;
  padding-bottom: 10%;
}

.WhatsappButton{
  padding: 1%;
}